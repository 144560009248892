@charset 'UTF-8';
@import './setting/variables';

.GPC0071 {
	max-width:(1284px+60px);
	padding-left:30px;
	padding-right:30px;
	@include screen(custom, max, $max-sm) {
		padding-left:24px;
		padding-right:24px;
		text-align:center !important;
	}
	
	.unit-list{
		@extend %clearfix;
		.unit-box {
			width:100%;
			float:left;
			[dir="rtl"] & {float:right;}
			@include screen(custom, max, $max-xs) {
				margin-bottom:34px;
				&:last-child{
					margin-bottom:0;
				}
			}
			& > div {
				clear:both;
			}
		}
	}	
	&.block1 {
		.title, .text {
			@include screen(custom, min, $min-md) {
				width: 900px/1284px * 100%;
			} 
		}
		&.align-left {
			.title, .text {
				float:left;
			}
		}
		&.align-center {
			.title, .text {
				margin:0 auto;
			}
		}
		&.align-right {
			.title, .text {
				float:right;
			}
		}
		.video a.see-video {
			/* LGEGMC-432 20200812 modify */
			&:before {
				width:62px;
				height:62px;
				margin:-31px 0 0 -31px;
				background-image:url('/lg5-common-gp/images/components/btn-play-lg.svg');
				@include screen(custom, max, $max-sm){
					width:48px;
					height:48px;
					margin:-24px 0 0 -24px;
					background-image:url('/lg5-common-gp/images/components/btn-play.svg');
				}				
			}
			/* // LGEGMC-432 20200812 modify */	
		}
	}
	&.block2{
		.unit-list{
			@extend %clearfix;
			 .unit-box {
				width:calc(100%/2 - 12px);
				margin-right:24px;
				&:last-child {
					margin-right:0;
				}
				[dir="rtl"] & {
					margin-right:0;
					margin-left:24px;
					&:last-child {
						margin-left:0;
					}
				}	
				@include screen(custom, max, $max-sm) {
					float:none;
					width:100%;
					margin:0 0 34px 0;
					&:last-child {
						margin-bottom:0;
					}
				}
				
			 }
		}
	}
	&.block3{
		.unit-list{
			@extend %clearfix;
			.unit-box {
				width:calc(100%/3 - 16px);
				margin-right:24px;	
				&:last-child {
					margin-right:0;
				}
				[dir="rtl"] & {
					margin-right:0;
					margin-left:24px;
					&:last-child {
						margin-left:0;
					}
				}
				@include screen(custom, max, $max-sm) {
					float:none;
					width:100%;
					margin:0 0 34px 0;
					&:last-child {
						margin-bottom:0;
					}
				}
			}
		}
	}
	.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6  {
		@include font-family($font-semibold);
		font-size:36px;
		line-height:44px;
		color:$color-black;
		margin-bottom:16px;
		@include screen(custom, max, $max-sm) {
			font-size:24px;
			line-height:28px;
			margin-bottom:8px;
		}
	}
	&.block1 {
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			@include screen(custom, max, $max-md) {
				font-size:32px;
				line-height:40px;
			}
			@include screen(custom, max, $max-sm) {
				font-size:24px;
				line-height:28px;
			}
		}
	}
	&.block2 {
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			@include screen(custom, max, $max-lg) {
				font-size:32px;
				line-height:40px;
			}
			@include screen(custom, max, $max-md) {
				font-size:28px;
				line-height:36px;
			}
			@include screen(custom, max, $max-sm) {
				font-size:24px;
				line-height:28px;
			}
		}
	}
	&.block3 {
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			font-size:32px;
			line-height:40px;
			@include screen(custom, max, $max-lg) {
				font-size:28px;
				line-height:36px;
			}
			@include screen(custom, max, $max-md) {
				font-size:24px;
				line-height:32px;
			}
			@include screen(custom, max, $max-sm) {
				font-size:24px;
				line-height:28px;
			}
		}
	}
	.text{
		margin-bottom:16px;
		font-size:16px;
		line-height:24px;
		color:$color-dimgray;
		@include screen(custom, max, $max-sm) {
			margin-bottom:11px;
		}
	}
	&.align-left {
		.cta-box {
			.btn{
				margin:0 6px 16px 0;
			}
			.link-text{
				margin:0 18px 10px 0;
			}
		}
	}
	&.align-center{
		.cta-box {
			.btn{
				margin:0 3px 16px 3px;
			}
			.link-text{
				margin:0 9px 10px 9px;
			}
		}
	}
	&.align-right{	
		.cta-box {
			.btn{
				margin:0 0 16px 6px;
			}
			.link-text{
				margin:0 0 10px 18px;
			}
		}
	}	
	&.align-bottom {
		.visual-box { 
			margin-bottom:24px;
			@include screen(custom, max, $max-sm) {
				margin-bottom:19px;
			}
		}	
	}
	.cta-box{
		margin-bottom:14px;
		@include screen(custom, max, $max-sm) {
			margin-bottom:9px;
		}
		.btn{
			margin:0 6px 10px 0;
		}
		.link-text {
			margin:0 18px 11px 0;
			color:$color-nightrider;
		}
		.ico-right{
			&:after {
				background: url($link-right-nightrider) no-repeat 0 1px; //LGCOMSPEED-6(8th)
			}
		}	

	}
	
	.visual-box{
		position:relative;
		width:100%;
		img{
			width:100%;
		}
		.cta-box{
			position:absolute;
			padding:0 30px 0 30px;
			bottom:20px;
			left:0;
			width:100%;
			margin-bottom:0;
			span{
				a{
					margin:0 24px 0 24px;
				}
			}
		}
		&.text-white {
			color:$color-white;
			background-color: $bg-gray;
			.cta-box{
				.link-text {
					color:$color-white;
				}
				.ico-right {
					&:after {
						background: url($link-right-white) no-repeat 0 1px; //LGCOMSPEED-6(8th)
					}
				}
			}
		}
	}
}